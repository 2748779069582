@import 'variables';
@import 'bootstrap/scss/functions';
@import 'bootstrap/scss/variables';
@import 'bootstrap/scss/variables-dark';
@import 'bootstrap/scss/maps';
@import 'bootstrap/scss/mixins';

$primary: $color-black;
$link-decoration: none;
$link-color: $color-black;
$link-hover-color: rgba($color-black, 0.7);
$enable-smooth-scroll: false;
$custom-spacers: (
    6: $spacer * 6,
    7: $spacer * 10
);
$spacers: map-merge($spacers, $custom-spacers);

$custom-gutters: (
    6: $spacer * 5,
    7: $spacer * 7
);
$gutters: map-merge($gutters, $custom-gutters);

@import 'bootstrap/scss/utilities';

// Helpers
@import 'bootstrap/scss/helpers';

// Utilities
@import 'bootstrap/scss/utilities/api';

// $form-floating-padding-x: 0;

// Layout & components
@import 'bootstrap/scss/root';
@import 'bootstrap/scss/reboot';
@import 'bootstrap/scss/type';
// @import 'bootstrap/scss/images';
@import 'bootstrap/scss/containers';
@import 'bootstrap/scss/grid';
// @import 'bootstrap/scss/tables';
@import 'bootstrap/scss/forms';
@import 'bootstrap/scss/buttons';
@import 'bootstrap/scss/transitions';
@import 'bootstrap/scss/dropdown';
@import 'bootstrap/scss/button-group';
@import 'bootstrap/scss/nav';
@import 'bootstrap/scss/navbar';
// @import 'bootstrap/scss/card';
@import 'bootstrap/scss/accordion';
// @import 'bootstrap/scss/breadcrumb';
// @import 'bootstrap/scss/pagination';
// @import 'bootstrap/scss/badge';
// @import 'bootstrap/scss/alert';
// @import 'bootstrap/scss/progress';
// @import 'bootstrap/scss/list-group';
// @import 'bootstrap/scss/close';
// @import 'bootstrap/scss/toasts';
@import 'bootstrap/scss/modal';
// @import 'bootstrap/scss/tooltip';
// @import 'bootstrap/scss/popover';
@import 'bootstrap/scss/carousel';
// @import 'bootstrap/scss/spinners';
