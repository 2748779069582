@import 'scss/bootstrap';
@import 'scss/colors';
@import 'scss/typography';
@import 'scss/tables';
@import 'scss/variables';
@import 'scss/components';
@import 'scss/aos';
@import 'scss/buttons';

.lazyload,
.lazyloading {
    opacity: 0;
}
.lazyloaded {
    opacity: 1;
    -webkit-transition: opacity 1s ease;
    transition: opacity 1s ease;
}

html,
body {
    height: 100%;
}

// Icon Utils

.icon-tiny {
    height: 8px;
}

.icon-small {
    height: 20px;
}

.icon-medium {
    height: 30px;
}

.icon-large {
    height: 40px;
}

.icon-giant {
    height: 100px;
}
.mat-mdc-dialog-surface {
    justify-content: space-between;
    display: flex;
}
