/*
$progress-bar-height: 20px;

mat-progress-bar {
    height: $progress-bar-height !important;

    & .mdc-linear-progress__buffer {
        height: $progress-bar-height !important;

        & .mdc-linear-progress__buffer-bar {
            height: $progress-bar-height !important;
            border-radius: 50px;
        }
    }
}
*/
