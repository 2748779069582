@import './variables';

@each $name, $code in $colors {
    .color-#{$name} {
        color: $code !important;
    }
    .background-#{$name} {
        background-color: $code !important;
    }
    .border-#{$name} {
        border: $code !important;
    }
}
