@import 'scss/colors';

.mat-mdc-table {
    background-color: white !important;
}

th {
    font-weight: bold !important;
}

td {
    color: $color-grey !important;
}
