@import 'variables';

.btn {
    border-radius: 5px;
    font-size: 14px;
}

.btn-unstyle {
    outline: none !important;
    border: none !important;
    background: none;
    padding: 0;

    &:hover,
    &:focus,
    &:active {
        outline: none !important;
    }
}

button {
    &:focus {
        box-shadow: none !important;
    }
}

.btn {
    display: inline-block;
    border-width: 1px;
    border-radius: 50px;
    padding: 15px 60px;
    font-weight: bold;
    @include media-breakpoint-down(md) {
        padding: 10px 30px;
    }
}

.btn-green-filled {
    border-color: $color-green;
    color: $color-black;
    font-weight: 700;
    background-color: $color-green;
    opacity: 1 !important;
    &:disabled,
    &:hover,
    &:focus {
        border-color: $color-green;
        background-color: $color-green !important;
        color: $color-black;
        opacity: 0.7 !important;
    }
}

.btn-white {
    border-color: $color-white;
    color: $color-black;
    font-weight: 700;
    background-color: $color-white;
    opacity: 1 !important;
    &:disabled,
    &:hover,
    &:focus {
        border-color: $color-white;
        background-color: $color-white !important;
        color: $color-black;
        opacity: 0.7 !important;
    }
}

.btn-black-filled {
    border-color: $color-black;
    color: $color-white;
    font-weight: 700;
    background-color: $color-black;
    opacity: 1 !important;
    &:disabled,
    &:hover,
    &:focus {
        border-color: $color-black;
        background-color: $color-black !important;
        color: $color-white;
        opacity: 0.7 !important;
    }
}

.btn-white-outline {
    border-color: $color-white;
    color: $color-white !important;
    font-weight: 700;
    background-color: transparent;
    opacity: 1 !important;
    &:disabled,
    &:hover,
    &:focus {
        border-color: $color-white;
        background-color: transparent !important;
        color: $color-white;
        opacity: 0.7 !important;
    }
}

.btn-black-outline {
    border-color: $color-black;
    color: $color-black;
    font-weight: 700;
    background-color: transparent;
    opacity: 1 !important;
    &:disabled,
    &:hover,
    &:focus {
        border-color: $color-black;
        background-color: transparent !important;
        color: $color-black;
        opacity: 0.7 !important;
    }
}

.btn-green-outline {
    border-color: $color-green;
    color: $color-black;
    font-weight: 700;
    background-color: transparent;
    opacity: 1 !important;
    &:disabled,
    &:hover,
    &:focus {
        border-color: $color-green;
        background-color: transparent !important;
        color: $color-black;
        opacity: 0.7 !important;
    }
}
