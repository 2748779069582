$color-white: #ffffff;
$color-black: #000000;
$color-green: #c8debe;
$color-green-dark: #4a724d;
$color-dark: #c8debe;
$color-beige: #fffbf4;
$color-grey: #747474;
$color-green-transparent: #c7ddbc33;
$color-orange: #ffa500;
$color-orange-transparent: #ffa50033;

$colors: (
    'white': $color-white,
    'black': $color-black,
    'green': $color-green,
    'beige': $color-beige,
    'grey': $color-grey,
    'green-transparent': $color-green-transparent,
    'orange': $color-orange,
    'orange-transparent': $color-orange-transparent
);
