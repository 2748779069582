@import './variables';
@import './bootstrap_essentials';

/* Fonts */
$font-oswald: 'Oswald', sans-serif;
$font-amiko: 'Amiko', sans-serif;

body {
    font-family: $font-amiko;
    color: $color-grey;
}

/* Headlines */

h1,
.text-size-xl {
    font-family: $font-oswald;
    font-size: 60px;
    font-weight: 700;
    text-transform: uppercase;
    margin-bottom: 46px;
    color: $color-black;

    @include media-breakpoint-down(lg) {
        font-size: 2.2rem;
        line-height: 2.5rem;
    }

    @include media-breakpoint-down(md) {
        font-size: 2rem;
        line-height: 2.5rem;
    }
}

h2,
.text-size-l {
    font-family: $font-oswald;
    text-transform: none;
    font-weight: 700;
    font-size: 42px;
    color: $color-black;
    margin-bottom: 30px;

    @include media-breakpoint-down(md) {
        font-size: 1.6rem;
    }
}

h3,
.text-size-m {
    font-family: $font-oswald;
    font-weight: 500;
    font-size: 25px;
    color: $color-black;
    margin-bottom: 15px;

    @include media-breakpoint-down(md) {
        font-size: 1.2rem;
    }
}

.heading {
    font-size: 14px;
    color: $color-green;
    text-transform: uppercase;
    font-weight: bold;
}
